import type { LngLat, Team, TranslationKey } from '@nexdynamic/squeegee-common';
import { AccountUser, Alert, notNullUndefinedEmptyOrZero, sortByCreatedDateAsc } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { Prompt } from '../Dialogs/Prompt';
import { TextDialog } from '../Dialogs/TextDialog';
import { Logger } from '../Logger';
import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../Schedule/ScheduleService';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { TrackingService } from '../Tracking/TrackingService';
import { Utilities } from '../Utilities';
import { AssignmentService } from './Assignees/AssignmentService';
import { UserAuthorisationService } from './UserAuthorisationService';
import { Workload } from './Workload';

export class UserService {
    static async sendAlert(accountUserOrTeam: AccountUser | Team): Promise<void> {
        const defaultSubject = ApplicationState.localise('alert-message.message-from-x', {
            name: UserService.getUser()?.name || ApplicationState.account.businessName || '',
        });

        const subjectDialog = new TextDialog(
            'alert-message.send-alert-subject-title',
            'alert-message.send-alert-subject-description',
            defaultSubject,
            '',
            v => (v.length > 30 ? 'alert-message.send-alert-invalid-subject-length' : true),
            false,
            'text',
            'general.next'
        );

        const contentDialog = new TextDialog(
            'alert-message.send-alert-content-title',
            'alert-message.send-alert-content-description',
            '',
            '',
            v => (v.length > 300 ? 'alert-message.send-alert-invalid-content-length' : true),
            false,
            'textarea',
            'general.send'
        );

        let subject = await subjectDialog.show();
        if (subjectDialog.cancelled || contentDialog.cancelled) return;
        if (!subject) subject = defaultSubject;

        const content = await contentDialog.show();
        if (contentDialog.cancelled) return;

        const emails =
            accountUserOrTeam.resourceType === 'team'
                ? (accountUserOrTeam as Team).members.map(m => UserService.getUserById(m)?.email).filter(notNullUndefinedEmptyOrZero)
                : [(accountUserOrTeam as AccountUser).email];

        const names =
            accountUserOrTeam.resourceType === 'team'
                ? (accountUserOrTeam as Team).members.map(m => UserService.getUserById(m)?.name).filter(notNullUndefinedEmptyOrZero)
                : [(accountUserOrTeam as AccountUser).name];

        if (
            !(await new Prompt(
                ApplicationState.localise('alert-message.send-alert-to-confirm', {
                    to: Utilities.localiseList(names as Array<TranslationKey>),
                }),
                `<b>${subject}</b><br><span style="white-space: pre-line;">${content}</span>` as TranslationKey
            ).show())
        ) {
            return;
        }

        for (const email of emails) {
            const alert = new Alert(subject, content, 'message', undefined, undefined, [email]);
            Data.put(alert);
        }
    }

    public static async delete(user: AccountUser) {
        try {
            if (!user?._id || !user?.email) return;

            const defaultId = AssignmentService.getDefaultAssignedTo();
            if (defaultId === user._id) await ScheduleService.setDefaultAssignee();

            await Data.delete(user);
            UserAuthorisationService.deactivateUser(user.email);
        } finally {
            AssignmentService.getDefaultAssigneeUserOrTeam(true);
        }
    }

    public static async updateUser(accountUser: AccountUser) {
        if (accountUser.email === ApplicationState.dataEmail) {
            if (ApplicationState.instance.account.name !== accountUser.name) {
                ApplicationState.instance.account.name = accountUser.name;
                ApplicationState.save();
            }

            return;
        }

        const usersToDelete = UserService.getUsers().filter(user => accountUser._id !== user._id && accountUser.email === user.email);
        await Data.delete(usersToDelete);

        accountUser.email = accountUser.email && accountUser.email.toLowerCase();
        await Data.put(accountUser);
    }

    public static userExists(email: string) {
        return !!UserService.getUser(email);
    }

    public static getUser(email?: string) {
        try {
            if (!email) email = (RethinkDbAuthClient.session && RethinkDbAuthClient.session.email) || '';

            if (email === ApplicationState.dataEmail) return this.accountOwnerUser;
            return Data.firstOrDefault<AccountUser>('accountuser', u => u.email === email);
        } catch (error) {
            Logger.error('Failed to get user', { email, error });
        }
    }

    public static getUserById(id?: string) {
        try {
            if (!id) return;
            const possibleUser = Data.get<AccountUser>(id);
            if (possibleUser?.resourceType !== 'accountuser') return;

            return possibleUser;
        } catch (error) {
            Logger.error('Failed to get user by ID', { id, error });
        }
    }

    private static _accountOwnerUser: AccountUser;
    public static get accountOwnerUser() {
        if (!UserService._accountOwnerUser) {
            const owners = Data.all<AccountUser>('accountuser', { email: ApplicationState.dataEmail });

            // De-dupe the owners.
            if (owners.length > 1) {
                const ownersToDelete = owners.slice(1);
                Data.delete(ownersToDelete);
            }

            let owner = owners.length && owners[0];
            if (!owner) {
                owner = new AccountUser(ApplicationState.dataEmail);
                owner.name = ApplicationState.instance.account.name || ApplicationState.dataEmail;
                Data.put(owner);
            }
            UserService._accountOwnerUser = owner;
        }
        return UserService._accountOwnerUser;
    }

    public static async updateCurrentUserLocation(lngLat: LngLat) {
        if (!RethinkDbAuthClient || !RethinkDbAuthClient.session || RethinkDbAuthClient.session.freeDevice) return;
        const userEmail = RethinkDbAuthClient.session.email;

        const user = Data.firstOrDefault<AccountUser>('accountuser', itm => itm.email === userEmail);
        if (user) TrackingService.update(user, lngLat);
    }

    public static getWorkers() {
        const userAuths = UserAuthorisationService.userAuths;
        return Data.all<AccountUser>('accountuser').filter(
            accountUser =>
                userAuths[accountUser.email] &&
                !userAuths[accountUser.email]?.deactivated &&
                userAuths[accountUser.email]?.roles?.includes('Worker')
        );
    }

    public static getUsers() {
        const userAuths = UserAuthorisationService.userAuths;
        return Data.all<AccountUser>('accountuser').filter(accountUser => userAuths[accountUser.email]);
    }

    public static getActiveUsers() {
        const userAuths = UserAuthorisationService.userAuths;
        return Data.all<AccountUser>('accountuser').filter(
            accountUser => userAuths[accountUser.email] && !userAuths[accountUser.email]?.deactivated
        );
    }

    public static getWorksloadsForUsers(
        scheduleItems: Array<ScheduleItem>,
        includeAll = true,
        includeUnassigned = true,
        selectedUserId?: string
    ) {
        const users = UserService.getWorkers();
        const workloads = Workload.getWorkloadsForUsers(
            users,
            [],
            scheduleItems,
            includeUnassigned,
            true,
            includeAll,
            false,
            selectedUserId
        );

        return workloads;
    }
    public static getWorksloadsForTeams(scheduleItems: Array<ScheduleItem>, date?: string, selectedUserId?: string) {
        const teams = UserService.getTeams(date);
        const hasDefaultAssignee = !!AssignmentService.getDefaultAssigneeUserOrTeam();
        const workloads = Workload.getWorkloadsForUsers([], teams, scheduleItems, !hasDefaultAssignee, true, true, false, selectedUserId);

        return workloads;
    }

    public static getWorkloadsForUsersAndTeams(scheduleItems: Array<ScheduleItem>, date?: string, selectedUserId?: string) {
        const teams = UserService.getTeams(date);

        const users = UserService.getWorkers();
        const hasDefaultAssignee = !!AssignmentService.getDefaultAssigneeUserOrTeam();
        const workloads: Array<Workload> = Workload.getWorkloadsForUsers(
            users,
            teams,
            scheduleItems,
            hasDefaultAssignee,
            true,
            true,
            false,
            selectedUserId
        );

        return workloads;
    }

    /**
     * Returns the assignee name from the passed assignedTo parma if its a team it returns the team name
     * @param assignedTo
     */
    public static getAssigneeNameFromAssignee(id: string) {
        let assignee = '';

        const user = UserService.getUser(id);
        if (user) {
            assignee = user.name;
        } else {
            const team = Data.get<Team>(id);
            if (team) {
                assignee = team.name;
            }
        }

        return assignee;
    }

    public static getUserOrTeam(id: string) {
        const user = UserService.getUserById(id);
        if (user) {
            const auth = UserAuthorisationService.userAuths[user.email];
            if (!auth || auth.deactivated) return;
            return user;
        }

        const team = Data.get<Team>(id);

        if (!team) return;
        return team;
    }

    public static getAllUsersOfTeam(team: Team): Array<AccountUser> {
        const members: Array<AccountUser> = [];
        for (const memberId of team.members) {
            const accountUser = UserService.getUserById(memberId);
            if (accountUser) members.push(accountUser);
        }
        return members;
    }

    public static getTeams(activeDate?: string) {
        if (!activeDate) return Data.all<Team>('team').slice();

        return Data.all<Team>('team')
            .filter(t => (!t.activeFrom || t.activeFrom <= activeDate) && (!t.activeTo || t.activeTo >= activeDate))
            .slice()
            .sort((x, y) => (x.name > y.name ? 1 : x.name < y.name ? -1 : 0));
    }

    public static cleanupDuplicateUsers() {
        const users = UserService.getUsers();

        // group by email
        const usersByEmail: { [email: string]: Array<AccountUser> } = {};
        for (const user of users) {
            if (!usersByEmail[user.email]) usersByEmail[user.email] = [];
            usersByEmail[user.email].push(user);
        }

        // delete groups with only one user
        for (const email in usersByEmail) {
            if (usersByEmail[email].length === 1) delete usersByEmail[email];
        }

        // Sort the groups by the oldest .createdDate first
        for (const email in usersByEmail) {
            usersByEmail[email].sort(sortByCreatedDateAsc);
        }

        // Delete all but the first user in each group
        for (const email in usersByEmail) {
            const usersToDelete = usersByEmail[email].slice(1);
            Data.delete(usersToDelete);
        }
    }
}
